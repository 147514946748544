import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { snakeCaseToHuman, hoursToDurationString } from '@mybonus/public';
import { useAPI, useMutation, useQuery, usePagination, APIOutput, APIInput } from '@mybonus/ui';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { DateTime, ErrorPopup, Paginate } from '../../../components';
import { MAILCHIMP } from '../../../config';

export type SyncJobListProps = {
	focusJobId?: number;
};

export function SyncJobList(props: SyncJobListProps) {
	const { pagination: input, setPage } = usePagination(1, 20);
	const { api } = useAPI();

	const list = useQuery(
		'admin.audience.sync.schedule.list',
		(args) => api.admin.audience.sync.schedule.list.query(args),
		input,
	);

	const update = useMutation<APIInput['admin']['audience']['sync']['schedule']['update']>(
		(input) => api.admin.audience.sync.schedule.update.mutate(input),
		{
			onSuccess: () => list.load(),
		},
	);

	useEffect(() => {
		list.load();
	}, [props.focusJobId]);

	return (
		<Box>
			<Box mb={2}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						<Typography variant="h6">Schedule</Typography>
					</Grid>

					<Grid item>
						<Grid container direction="row">
							<Paginate state={list.data?.pagination} setPage={setPage} />

							<LoadingButton onClick={() => list.load()} loading={list.isLoading}>
								<RefreshIcon />
							</LoadingButton>
						</Grid>
					</Grid>
				</Grid>

				{list.isLoading && <LinearProgress />}
			</Box>

			<ErrorPopup error={list.error} onClose={list.reset} onRetry={list.load} />
			<ErrorPopup error={update.error} onClose={update.reset} />

			<Snackbar open={!!update.isSuccess} autoHideDuration={6000} onClose={update.reset}>
				<Alert onClose={update.reset} severity="success">
					Job updated!
				</Alert>
			</Snackbar>

			{list.data?.items && (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell sx={{ textAlign: 'right' }}>Audience</TableCell>
								<TableCell sx={{ width: '30px' }}></TableCell>
								<TableCell>Destination</TableCell>
								<TableCell>Last run</TableCell>
								<TableCell>Frequency</TableCell>
								<TableCell>Active date</TableCell>
								<TableCell>Timing</TableCell>
								<TableCell>Repeat</TableCell>
								<TableCell>Added</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list.data.items.map((job) => (
								<TableRow
									key={job.id}
									sx={{
										'& td': {
											transition: 'background-color 0.2s ease-in-out, opacity 0.2s ease-in-out',
											...(job.id === props.focusJobId ? { backgroundColor: '#d0f4e4' } : {}),
											...(!job.active || job.status == 'S' ? { opacity: '0.4' } : {}),
										},
									}}
								>
									<TableCell scope="row">{job.id}</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>
										<MuiLink component={Link} to={`/app/marketing/audiences/${job.audience_id}`}>
											{job.audience_name}
										</MuiLink>
									</TableCell>
									<TableCell>
										{job.status !== 'E' ? (
											<>
												{job.recurring ? (
													<Tooltip title={`Every ${job.recurring} min`}>
														<AllInclusiveIcon color={job.active ? 'inherit' : 'disabled'} />
													</Tooltip>
												) : (
													<ArrowIcon />
												)}
											</>
										) : (
											<Tooltip title={job.meta || ''}>
												<ErrorOutlineIcon color="error" />
											</Tooltip>
										)}
									</TableCell>
									<TableCell>
										<DestinationLink job={job} />
									</TableCell>
									<TableCell>
										{job.last_run ? <DateTime type="distance" date={job.last_run} /> : <>-</>}
									</TableCell>
									<TableCell>{job.recurring ? <>Every {job.recurring} min</> : 'Once'}</TableCell>
									<TableCell>
										{job.recurring ? (
											<>
												From <DateTime date={job.active_from} />
												{job.active_to ? (
													<>
														{' '}
														until <DateTime date={job.active_to} />
													</>
												) : undefined}
											</>
										) : (
											<>
												at <DateTime date={job.active_from} />
											</>
										)}
									</TableCell>
									<TableCell>
										{job.active_from_hour && job.active_hours ? (
											<>
												{('00' + job.active_from_hour).slice(-2)}
												:00 -{' '}
												{('00' + Math.min(23, job.active_from_hour + job.active_hours)).slice(-2)}
												:00
											</>
										) : (
											'-'
										)}
									</TableCell>
									<TableCell>
										{job.rejoin_after_hours && job.rejoin_limit ? (
											<Tooltip
												title={`A member can rejoin this audience ${
													job.rejoin_limit
												} times (fall out and back in). The rejoin will not occur before ${hoursToDurationString(
													job.rejoin_after_hours,
												)} after last join.`}
											>
												<span>
													Repeat max {job.rejoin_limit} times ({job.rejoin_limit + 1} total) with at
													least {hoursToDurationString(job.rejoin_after_hours)} apart
												</span>
											</Tooltip>
										) : (
											'-'
										)}
									</TableCell>
									<TableCell>
										<DateTime date={job.created_at} />
									</TableCell>
									<TableCell>
										{['Q', 'R'].includes(job.status) && (
											<Tooltip title="Pause job">
												<span>
													<IconButton
														disabled={!job.active || update.isLoading}
														onClick={() =>
															update.mutate({
																id: job.id,
																active: !job.active,
															})
														}
													>
														<PauseCircleOutlineIcon />
													</IconButton>
												</span>
											</Tooltip>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<Grid container mt={2} direction="row" justifyContent="flex-end">
				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Grid>
		</Box>
	);
}

interface DestinationLinkProps {
	job: APIOutput['admin']['audience']['sync']['schedule']['list']['items'][number];
}

function DestinationLink(props: DestinationLinkProps) {
	let url;
	const { destination, destination_id } = props.job;

	switch (props.job.destination) {
		case 'MAILCHIMP':
			url = `https://${MAILCHIMP.server}.admin.mailchimp.com/lists/segments/members/tags-filter?list_id=${MAILCHIMP.masterListId}&tag_ids[]=${destination_id}`;
			break;
		case 'NOTIFICATION':
			url = `/app/marketing/messages/notifications/list/${destination_id}`;
			break;
		case 'CASHBACK_SHARE':
			url = `/app/marketing/cashback-shares/list/${destination_id}`;
			break;
		default:
			url = '#';
	}

	const isExternal = url.startsWith('https://');
	const text = snakeCaseToHuman(destination);

	return isExternal ? (
		<MuiLink href={url} target="_blank">
			{text}
		</MuiLink>
	) : (
		<MuiLink component={Link} to={url}>
			{text}
		</MuiLink>
	);
}
