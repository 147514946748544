import { Paper } from '@mui/material';
import type { UniversalIntent } from '@mybonus/public';
import { useState } from 'react';

import { Page, UniversalLinkPreview, UniversalIntentEditor } from '../../components';

export function UniversalLinkGeneratorView() {
	const [intent, setIntent] = useState<UniversalIntent>();
	const [forceApp, setForceApp] = useState(false);

	return (
		<Page title="Generate Universal Links">
			<Paper sx={{ p: 4 }}>
				<UniversalIntentEditor
					onChange={(i, a) => {
						setIntent(i);
						setForceApp(a);
					}}
					forceAppOption
				/>

				{!!intent && <UniversalLinkPreview intent={intent} forceApp={forceApp} />}
			</Paper>
		</Page>
	);
}
