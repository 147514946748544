import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import type { PaginationInfo } from '@mybonus/public';

export type PaginationProps = {
	state?: PaginationInfo | undefined;
	setPage: (page: number) => void;
};

export function Paginate(props: PaginationProps) {
	return (
		<Stack sx={{ alignItems: 'flex-end' }}>
			<Pagination
				count={props.state?.pages || 1}
				page={props.state?.page || 1}
				onChange={(_, p) => props.setPage(p)}
				color="primary"
				size="small"
			/>
			<Typography variant="caption" sx={{ pr: 2 }} color="gray">
				{props.state?.totalRecords} records
			</Typography>
		</Stack>
	);
}
