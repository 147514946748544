import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { formatGender } from '@mybonus/public';
import { useAPI, useQuery, usePagination, Row } from '@mybonus/ui';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Page, ErrorPopup, DateTime, Paginate } from '../../components';

export function UserListView() {
	const [searchParams, setSearchParams] = useSearchParams({ freetext: '', page: '1' });
	const initialPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
	const { pagination, setPage, page } = usePagination(initialPage);
	const { api } = useAPI();
	const [searchTerm, setSearchTerm] = useState('');

	const input = useMemo(
		() => ({
			pagination: pagination.pagination,
			freetext: searchParams.get('freetext') || undefined,
		}),
		[pagination, searchParams],
	);

	const list = useQuery('admin.user.list', (args) => api.admin.user.list.query(args), input);

	const onSearch = useCallback(() => {
		searchParams.set('freetext', searchTerm);
		setSearchParams(searchParams);
		setPage(1);
	}, [searchTerm]);

	useEffect(() => {
		searchParams.set('page', `${page}`);
		setSearchParams(searchParams);
	}, [page]);

	return (
		<Page title="Users">
			<Row apart>
				<TextField
					sx={{ minWidth: '400px' }}
					label="Search"
					placeholder="Search for ID, SSN, email, phone, name or link"
					size="small"
					defaultValue={searchParams.get('freetext')}
					onChange={(e) => setSearchTerm(e.target.value)}
					onKeyDown={(ev) => {
						if (ev.key === 'Enter') {
							onSearch();
						}
					}}
				/>

				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Row>

			{list.isLoading && <LinearProgress />}

			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />

			{list && (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Phone</TableCell>
								<TableCell>Code</TableCell>
								<TableCell>SSN</TableCell>
								<TableCell>Properties</TableCell>
								<TableCell>Registered</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list.data?.items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										'& td': { ...(!row.active ? { opacity: '0.4' } : {}) },
									}}
								>
									<TableCell scope="row">{row.id}</TableCell>
									<TableCell>
										<MuiLink component={Link} to={`/app/users/${row.id}`}>
											{row.email}
										</MuiLink>
									</TableCell>
									<TableCell>{row.fullname}</TableCell>
									<TableCell>{row.phone}</TableCell>
									<TableCell>
										<Chip
											variant="outlined"
											size="small"
											label={row.link}
											sx={{ fontFamily: 'monospace' }}
										/>
									</TableCell>
									<TableCell>
										{row.ssn ? (
											<Tooltip title={row.ssn}>
												<CheckCircleIcon color="primary" />
											</Tooltip>
										) : (
											''
										)}
									</TableCell>
									<TableCell>
										<Stack spacing={1} direction="row">
											{!row.allowAcknowledge && (
												<Tooltip title="Acknowledge feature is not allowed for this user">
													<Chip
														size="small"
														color="warning"
														label="Acknowledge disabled"
														variant="outlined"
													/>
												</Tooltip>
											)}
											{row.gender && (
												<Chip
													size="small"
													color="info"
													label={formatGender(row.gender)}
													variant="outlined"
												/>
											)}
											{!!row.anonymizedAt && (
												<Chip size="small" color="warning" label="Anonymized" variant="outlined" />
											)}
										</Stack>
									</TableCell>
									<TableCell>
										<DateTime date={row.registered} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<Grid container direction="row" justifyContent="flex-end">
				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Grid>
		</Page>
	);
}
