import { UserGender } from '../types';
import { toPascalCase } from './data';

export function getFullName(user: Partial<{ firstname: string; lastname: string }>): string | null {
	if (!user.firstname && !user.lastname) {
		return null;
	}

	return `${user.firstname} ${user.lastname}`.trim();
}

export function normalizePhone(input: string): string | null {
	const result = input.replace(/\D/g, '');

	if (result.startsWith('46')) {
		return result;
	}

	if (result.startsWith('07')) {
		return '46' + result.slice(1);
	}

	return null;
}

export function formatGender(gender: UserGender | null): string {
	if (!gender) {
		return '';
	}

	return toPascalCase(gender);
}
