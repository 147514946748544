import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
	ThemeProvider as StyledThemeProvider,
	mainTheme,
	APIProvider,
	AuthProvider,
	ToastsProvider,
	ToastsOverlay,
} from '@mybonus/ui';

import { client } from './api';
import { Router } from './routes';

const theme = createTheme({
	spacing: 8,
	palette: {
		background: {
			paper: '#fff',
		},
		text: {
			primary: '#333',
			secondary: '#555',
		},
		primary: {
			main: '#10b66a',
			contrastText: '#fff',
		},
		action: {
			active: '#001E3C',
		},
	},
	components: {
		MuiTable: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: {
				root: {
					minWidth: '650px',
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:hover': {
						backgroundColor: '#fafafa',
					},
					'&:nth-of-type(even)': {
						backgroundColor: '#fcfcfc',
					},
					'&:last-child td, &:last-child th': { border: 0 },
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fontSize: 'medium',
				},
			},
		},
	},
});

export function App() {
	return (
		<MUIThemeProvider theme={theme}>
			<StyledThemeProvider theme={mainTheme}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<APIProvider client={client}>
						<ToastsProvider>
							<AuthProvider>
								<Router />
								<ToastsOverlay />
							</AuthProvider>
						</ToastsProvider>
					</APIProvider>
				</LocalizationProvider>
			</StyledThemeProvider>
		</MUIThemeProvider>
	);
}
