import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
	APIOutput,
	APIInput,
	useForm,
	useMutation,
	useAPI,
	emailValidation,
	nameValidation,
	ssnValidation,
} from '@mybonus/ui';
import { useCallback, useState } from 'react';

import {
	ErrorPopup,
	DateTime,
	Switch,
	UpsertButton,
	StatusSnackbar,
	Confirm,
} from '../../../components';

export type GeneralTabProps = {
	user: APIOutput['admin']['user']['single'];
	onUserChange?: () => void;
};

export function GeneralTab(props: GeneralTabProps) {
	const { api } = useAPI();
	const update = useMutation<APIInput['admin']['user']['update']>((input) =>
		api.admin.user.update.mutate(input),
	);
	const anonymize = useMutation<APIInput['admin']['user']['anonymize']>((input) =>
		api.admin.user.anonymize.mutate(input),
	);

	const [showAnonymize, setShowAnonymize] = useState(false);

	const form = useForm<{
		email: string;
		active: boolean;
		allowAcknowledge: boolean;
		ssn: string;
		firstname: string;
		lastname: string;
	}>({
		onSubmit: () => {
			update.mutate({ id: props.user.id, data: form.data }).then(() => {
				props.onUserChange?.();
			});
		},
		validations: {
			email: emailValidation(),
			ssn: ssnValidation(),
			firstname: nameValidation(),
			lastname: nameValidation(),
		},
		initialValues: {
			email: props.user.email,
			active: props.user.active,
			ssn: props.user.ssn || '',
			firstname: props.user.firstname || '',
			lastname: props.user.lastname || '',
			allowAcknowledge: props.user.allowAcknowledge,
		},
	});

	const error = update.error || anonymize.error;
	const handleReset = useCallback(() => {
		update.reset();
		anonymize.reset();
	}, []);

	return (
		<TabPanel value="general" sx={{ maxWidth: '600px' }}>
			<Typography variant="subtitle2">User ID: {props.user.id}</Typography>
			<Typography variant="subtitle2">
				Registered at <DateTime type="plain" date={props.user.registered} />
			</Typography>
			{props.user.anonymizedAt && (
				<Typography variant="subtitle2">
					Anonymized at <DateTime type="plain" date={props.user.anonymizedAt} />
				</Typography>
			)}

			<Stack spacing={2} sx={{ mt: 4 }}>
				<TextField
					required
					value={form.data.email}
					label="Email"
					error={!!form.errors.email}
					placeholder="me@example.com"
					size="small"
					onChange={(e) => form.handleChange('email')(e.target.value)}
				/>

				<TextField
					value={form.data.ssn}
					label="SSN"
					error={!!form.errors.ssn}
					placeholder="199001010000"
					size="small"
					onChange={(e) => form.handleChange('ssn')(e.target.value)}
				/>

				<Stack spacing={2} direction="row">
					<TextField
						value={form.data.firstname}
						label="Firstname"
						error={!!form.errors.firstname}
						placeholder="Sven"
						size="small"
						onChange={(e) => form.handleChange('firstname')(e.target.value)}
						sx={{ width: '100%' }}
					/>

					<TextField
						value={form.data.lastname}
						label="Lastname"
						error={!!form.errors.lastname}
						placeholder="Svensson"
						size="small"
						onChange={(e) => form.handleChange('lastname')(e.target.value)}
						sx={{ width: '100%' }}
					/>
				</Stack>
			</Stack>

			<Stack spacing={1} py={2}>
				<Switch
					on={form.data.active}
					onChange={() => form.handleChange('active')(!form.data.active)}
					label="Active"
				/>

				<Switch
					on={form.data.allowAcknowledge}
					onChange={() => form.handleChange('allowAcknowledge')(!form.data.allowAcknowledge)}
					label="Allow acknowledge"
				/>
			</Stack>

			<Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
				<UpsertButton
					disabled={update.isLoading}
					isLoading={update.isLoading}
					onClick={form.handleSubmit}
					mode="update"
				/>

				{/*
				<Button color="error" endIcon={<NoAccountsIcon />} onClick={() => setShowAnonymize(true)}>
					Anonymize
				</Button>
				*/}
			</Stack>

			<StatusSnackbar open={!!update.isSuccess} onClose={update.reset} text="User updated" />
			<StatusSnackbar
				open={!!anonymize.isSuccess}
				onClose={anonymize.reset}
				text="User was anonymized"
			/>

			<ErrorPopup error={error} onClose={handleReset} />

			<Confirm
				open={showAnonymize}
				onConfirm={() => {
					anonymize
						.mutate({ userId: props.user.id, proofFileBlob: '' as any })
						.then(() => props.onUserChange?.());
					setShowAnonymize(false);
				}}
				onClose={() => setShowAnonymize(false)}
				title="Anonymize user?"
				message="Are you sure you want to anonymize this user?"
				isLoading={anonymize.isLoading}
			/>
		</TabPanel>
	);
}
