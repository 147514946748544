import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { useAPI, useQuery } from '@mybonus/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Page, ErrorPopup } from '../../components';
import { GeneralTab } from './GeneralTab';

export function UserDetailView() {
	const { api } = useAPI();

	const params = useParams();
	const input = useMemo(() => ({ id: Number(params.id) }), [params.id]);
	const single = useQuery('admin.user.single', (args) => api.admin.user.single.query(args), input);

	return (
		<Page title={`Editing user #${input.id}`}>
			{single.isLoading && <LinearProgress />}

			{!!single.data && (
				<Stack direction="row" spacing={1}>
					{!single.data.active && (
						<Chip size="small" color="warning" label="Inactive" variant="outlined" />
					)}
					{!single.data.allowAcknowledge && (
						<Chip size="small" color="warning" label="Acknowledge disabled" variant="outlined" />
					)}
					{single.data.anonymizedAt && (
						<Chip size="small" color="warning" label="Anonymized" variant="outlined" />
					)}
				</Stack>
			)}

			<Stack sx={{ alignItems: 'flex-start' }} spacing={2}>
				<TabContext value="general">
					<TabList>
						<Tab value="general" label="General" />
						<Tab value="cashbacks" label="Cashbacks" disabled />
						<Tab value="giftcards" label="Giftcards" disabled />
						<Tab value="withdrawals" label="Withdrawals" disabled />
						<Tab value="clicks" label="Clicks" disabled />
						<Tab value="cashback_shares" label="Cashback shares" disabled />
						<Tab value="recruitments" label="Recruitments" disabled />
						<Tab value="log" label="Logs" disabled />
					</TabList>
					{!!single.data && (
						<Paper sx={{ minWidth: '600px', width: '100%', maxWidth: '1000px' }}>
							<GeneralTab
								user={single.data}
								onUserChange={() => single.load({ refetch: true, bypassCache: true })}
							/>
						</Paper>
					)}
				</TabContext>
			</Stack>

			<ErrorPopup error={single.error} onRetry={single.load} onClose={single.reset} />
		</Page>
	);
}
