import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Sw from '@mui/material/Switch';
import type { ReactNode } from 'react';

export type SwitchProps = {
	on: boolean;
	onChange: (newValue: boolean) => void;
	label: string;
	content?: ReactNode;
};

export function Switch(props: SwitchProps) {
	return (
		<Box pl={2}>
			<FormControlLabel
				control={<Sw checked={props.on} onChange={() => props.onChange(!props.on)} size="small" />}
				label={props.label}
			/>
			{props.content}
		</Box>
	);
}
