import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ErrorMessage } from '@mybonus/ui';

import {
	Page,
	ErrorPopup,
	LocalizationEditor,
	HeroSelect,
	StatusSnackbar,
	TagSelect,
	CmsContentEditor,
	UpsertButton,
	Switch,
} from '../../components';
import { useCmsPageUpsertView } from './PageUpsertView.hooks';

export function PageUpsertView() {
	const {
		notFound,
		params,
		single,
		mode,
		form,
		isSuccess,
		isLoading,
		handleReset,
		error,
		previewUrl,
	} = useCmsPageUpsertView();

	return (
		<Page title={!single.isLoading ? `${mode} page` : ''} width="contained">
			{notFound && (
				<ErrorMessage
					error={`Page with id ${params.id} could not be found. You can create a new one below.`}
				/>
			)}

			<TextField
				required
				value={form.data.slug}
				label="Slug (part of URL)"
				error={!!form.errors.slug}
				placeholder="some-slug"
				size="small"
				onChange={(e) => form.handleChange('slug')(e.target.value)}
			/>

			{previewUrl && (
				<Stack spacing={1} direction="row">
					<Typography>Preview page:</Typography>
					<MuiLink href={previewUrl} target="_blank">
						<Typography>{previewUrl}</Typography>
					</MuiLink>
				</Stack>
			)}

			<LocalizationEditor
				localizationMap={form.data.title}
				onChange={(value) => form.handleChange('title')(value)}
				renderer={(value, onChange, language) => (
					<TextField
						required
						value={value}
						label={`Title (${language})`}
						error={!!form.errors.title}
						placeholder="Some title"
						size="small"
						onChange={(e) => onChange(e.target.value)}
					/>
				)}
			/>

			<LocalizationEditor
				localizationMap={form.data.content}
				onChange={(value) => form.handleChange('content')(value)}
				renderer={(value, onChange) => <CmsContentEditor value={value} onChange={onChange} />}
			/>

			<TagSelect
				value={form.data.tagId}
				onChange={form.handleChange('tagId')}
				error={form.errors.tagId}
			/>

			<HeroSelect
				value={form.data.heroId}
				onChange={form.handleChange('heroId')}
				error={form.errors.heroId}
			/>

			<Switch
				on={form.data.showInMenu}
				onChange={() => form.handleChange('showInMenu')(!form.data.showInMenu)}
				label="Show in menu"
			/>

			<TextField
				sx={{ my: 2 }}
				required
				type="number"
				value={form.data.order}
				label="Order"
				error={!!form.errors.order}
				size="small"
				onChange={(e) => form.handleChange('order')(Number(e.target.value))}
				InputProps={{
					inputProps: { min: 0, max: 100 },
				}}
			/>

			<Switch
				on={form.data.active}
				onChange={() => form.handleChange('active')(!form.data.active)}
				label="Active"
			/>

			<StatusSnackbar
				open={isSuccess}
				onClose={handleReset}
				text={`Page ${mode === 'Create' ? 'created' : 'updated'}!`}
			/>

			<UpsertButton
				disabled={isLoading}
				isLoading={isLoading}
				onClick={form.handleSubmit}
				mode={mode === 'Update' ? 'update' : 'create'}
			/>

			<ErrorPopup error={error} onClose={handleReset} />
		</Page>
	);
}
