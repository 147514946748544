import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import { Page, ErrorPopup, Confirm, DeleteIcon, ActivateIcon } from '../../components';
import { useFaqItemList } from './FaqItemListView.hooks';

export function FaqItemListView() {
	const { list, remove, deleteId, setDeleteId, deletedIds, setDeletedIds, handleInactivate } =
		useFaqItemList();

	return (
		<Page title="FAQ Items">
			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />
			<ErrorPopup error={remove.error} onClose={remove.reset} />

			<Confirm
				open={!!deleteId || remove.isLoading}
				onConfirm={() => {
					remove.mutate({ id: Number(deleteId) });
					setDeleteId(undefined);
					setDeletedIds([...deletedIds, Number(deleteId)]);
				}}
				onClose={() => setDeleteId(undefined)}
				title="Delete item?"
				message="Are you sure you want to delete this item?"
				isLoading={remove.isLoading}
			/>

			<Link to="/app/cms/faq/items/create">
				<Button variant="contained">New Item</Button>
			</Link>
			{list.isLoading && <LinearProgress />}

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Question</TableCell>
							<TableCell>Prio</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list?.data?.map((row) => (
							<TableRow
								key={row.id}
								sx={{
									...(deletedIds.indexOf(row.id) !== -1 || !row.active ? { opacity: 0.3 } : {}),
								}}
							>
								<TableCell component="th" scope="row">
									{row.id}
								</TableCell>
								<TableCell>
									<MuiLink component={Link} to={`/app/cms/faq/items/${row.id}`}>
										{row.question}
									</MuiLink>
								</TableCell>
								<TableCell>{row.prio}</TableCell>
								<TableCell>
									<ActivateIcon
										isActive={!!row.active}
										onClick={() => handleInactivate(row.id, !!row.active)}
									/>
									<DeleteIcon onClick={() => setDeleteId(row.id)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Page>
	);
}
