import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { UniversalIntent } from '@mybonus/public';
import { useUniversalIntentNavigation } from '@mybonus/ui';

import env from '../../../env';

export type UniversalLinkPreviewProps = {
	intent: UniversalIntent;
	forceApp?: boolean;
};

export function UniversalLinkPreview(props: UniversalLinkPreviewProps) {
	const intent = useUniversalIntentNavigation();

	const url = intent.buildUniversalLink(env.WEB_BASE_URL, props.intent, !!props.forceApp);

	return (
		<>
			<Typography>Built link:</Typography>
			<MuiLink href={url} target="_blank">
				<Typography>{url}</Typography>
			</MuiLink>
		</>
	);
}
